<script>
export default {
  name: 'ConditionDocView.vue'
}
</script>

<template>
  <div class="condition-container">
    <div class="condition">
      <div class="margin10">
        <h2>Condizioni di utilizzo</h2>
      </div>

      <div class="margin10">Le fotografie originali sono di proprietà della Associazione Nazionale Granatieri di Sardegna,
        sezione di Milano. Le immagini sono messe gratuitamente a disposizione del pubblico con licenza Creative Commons
        CC-BY-NC-ND, pertanto, in caso di loro utilizzo in qualunque altro modo: </div>

      <div class="margin10"> A) Dovrà essere riconosciuta una menzione di paternità adeguata, fornito un link alla licenza
        e indicare se sono state effettuate delle modifiche;</div>

      <div class="margin10">
        B) Il materiale non potrà essere utilizzato per scopi commerciali;
      </div>

      <div class="margin10">
        C) Se il materiale viene remixato, trasformato, o ci si basa su di esso, non sarà possibile distribuire il
        materiale così modificato.
      </div>

      <div class="margin10">
        Non sono fornite garanzie. La licenza può non conferire tutte le autorizzazioni necessarie per l'utilizzo che ci
        si prefigge. Ad esempio, diritti di terzi come i diritti all'immagine originale, alla riservatezza e i diritti
        morali potrebbero restringere gli usi che ci si prefigge sul materiale.
      </div>

      <div class="margin10">
        <div class="img-container"></div>
      </div>

      <div class="dots margin10">
        <span>*</span>
        <span>*</span>
        <span>*</span>
      </div>

      <div class="margin10">
        <h2>Credits</h2>
      </div>

      <div class="margin10">

        <div class="margin10">
          <span>La digitalizzazione delle immagini è stata curata da: </span>
        </div>

        <div class="margin10">
          <span>- Michail Paschalidis (immagini da 1 a 92)</span>
        </div>

        <div class="margin10">
          <span>- Alessio Franconi <a href="https://www.franconiphotos.eu/" class="mail-style"
              target="_blank">https://www.franconiphotos.eu/</a> (tutte le altre immagini).</span>
        </div>
      </div>

      <div class="margin10">
        I testi sono di Matteo Mazzucchi <a href="mazzucchistoria@gmail.com" class="mail-style"
          target="_blank">mazzucchistoria@gmail.com</a> , che ne conserva la proprietà intellettuale. Ogni diverso
        utilizzo del materiale testuale dovrà essere preventivamente autorizzato dall'autore.
      </div>

      <div class="margin10">
        Il sito web è stato realizzato da Nicolas Di Mauro <span class="mail-style">nico17022001boolean@gmail.com </span>
      </div>

      <div class="margin10">
        Il progetto editoriale è di Leonardo Sultato <span class="mail-style">leonardosultato@virgilio.it </span>
      </div>

      <div class="margin10">
        Il progetto di digitalizzazione e pubblicazione in rete è stato attuato con il contributo di Regione Lombardia CUP
        F49I23000590002.
      </div>

      <div class="margin10">
        <h2>Contatti</h2>
      </div>

      <div class="margin10">
        Associazione Nazionale Granatieri di Sardegna - ANGS <br>
        Sezione di Milano <br>
        C/o Caserma XXIV Maggio <br>
        Via Vincenzo Monti 59 <br>
        20145 MILANO MI
      </div>

      <div class="margin10">
        <span class="mail-style">granatieri.milano@gmail.com</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.condition-container {
  background-color: #1b101c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.condition {
  width: 80%;
  background-color: #313131;
  color: white;
  border: solid 1px white;
  padding: 80px;
  line-height: 1.5;
}

.img-container {
  width: 110px;
  height: 50px;
  background-image: url(../img/Home/condition-img.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.mail-style {
  color: wheat;
  text-decoration: none;
}

.dots {
  text-align: center;
  color: white;
}

span {
  margin-right: 20px;
}

.margin10 {
  margin-bottom: 20px;
}

@media screen and (max-width: 450px) {
    .condition{
      width: 80%;
      background-color: #313131;
      color: white;
      border: solid 1px white;
      padding: 10px;
      line-height: 1.5;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      font-size: small;
    }
  }
</style>