<script>
import HeaderView from '@/components/HeaderView.vue';
import SearchDocViewVue from '@/components/SearchDocView.vue';
import MainDocViewVue from '@/components/MainDocView.vue';
import documentsData from '@/documents.json';

export default {
  name: 'DocumentView',
  components: {
    HeaderView,
    SearchDocViewVue,
    MainDocViewVue
  },

  data() {
    return {
      selectedFilter: '', // Inizializza il filtro selezionato
      documents: documentsData,
    };
  },

  methods: {
    updateFilter(filter) {
      // Aggiorna il filtro selezionato quando viene emesso l'evento
      this.selectedFilter = filter;
    },

    resetStartIndex() {
      // Reimposta startIndex a 0 quando viene emesso l'evento
      this.$refs.mainDocView.startIndex = 0;
    },
  },
};
</script>

<template>
  <div class="document">

    <HeaderView />

    <div class="container">
      <SearchDocViewVue :selectedFilter="selectedFilter" @filter-selected="updateFilter" />

      <MainDocViewVue :selectedFilter="selectedFilter" :documents="documents" />
    </div>

    <router-view />
  </div>
</template>

<style scoped lang="scss">
  .document{
    height: 100vh;
    width: 100%;
  }

  .container{
    display: flex;
    height: calc(100% - 70px);
    width: 100%;
    overflow: hidden;
  }

</style>