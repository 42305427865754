var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_vm._m(0),_vm._m(1),(_vm.$router.currentRoute.name === 'document')?_c('div',{staticClass:"space hide"},[_c('a',{staticClass:"yellow",attrs:{"href":"/"}},[_vm._v("Home")])]):_vm._e(),(_vm.$router.currentRoute.name === 'home')?_c('div',{staticClass:"space hide"},[_c('a',{staticClass:"yellow",attrs:{"href":"/document"}},[_vm._v("Entra")])]):_vm._e(),_vm._m(2),_vm._m(3),_c('div',{staticClass:"dropdown",on:{"click":_vm.toggleDropdown}},[_vm._m(4),_c('div',{staticClass:"dropdown-content"},[(_vm.$router.currentRoute.name === 'document')?_c('a',{attrs:{"href":"/"}},[_vm._v("Home")]):_vm._e(),(_vm.$router.currentRoute.name === 'home')?_c('a',{attrs:{"href":"/document"}},[_vm._v("Entra")]):_vm._e(),_vm._m(5)])]),_c('router-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space",attrs:{"id":"logo-container"}},[_c('img',{attrs:{"src":require("../img/Home/logo.jpg"),"alt":"Logo Granatieri"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"document-name"},[_vm._v("Associazione Nazionale Granatieri di Sardegna "),_c('br'),_vm._v(" Sezione di Milano "),_c('br'),_vm._v("Archivio Fotografico")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space hide"},[_c('a',{staticClass:"yellow",attrs:{"href":"/condition","target":"_blank"}},[_vm._v("Condizioni di utilizzo "),_c('br'),_vm._v(" Contatti - Credits")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex space"},[_c('div',{staticClass:"d-flex lomb-container"},[_c('div',{staticClass:"my5"},[_vm._v(" Con il contributo di ")]),_c('a',{attrs:{"href":"https://www.regione.lombardia.it/wps/portal/istituzionale/","target":"_blank"}},[_c('div',{staticClass:"bg-white"},[_c('img',{attrs:{"src":require("../img/Home/lombardia-logo.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"dropbtn"},[_c('i',{staticClass:"fa-solid fa-bars"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/condition","target":"_blank"}},[_vm._v("Condizioni di utilizzo "),_c('br'),_vm._v("Contatti - Credits")])
}]

export { render, staticRenderFns }