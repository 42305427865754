<script>
export default {
  name: 'HeaderView',
  data() {
    return {
      isDropdownOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      const dropdownContent = document.querySelector(".dropdown-content");
      if (this.isDropdownOpen) {
        dropdownContent.style.display = "block";
      } else {
        dropdownContent.style.display = "none";
      }
    }
  }
}
</script>


<template>
  <header>

    <div id="logo-container" class="space">
      <img src="../img/Home/logo.jpg" alt="Logo Granatieri">
    </div>

    <div class="document-name">Associazione Nazionale Granatieri di Sardegna <br> Sezione di Milano <br>Archivio Fotografico</div>
      
    <div class="space hide" v-if="$router.currentRoute.name === 'document'"><a class="yellow" href="/">Home</a></div>

    <div class="space hide" v-if="$router.currentRoute.name === 'home'"><a class="yellow" href="/document">Entra</a></div>

    <div class="space hide"><a href="/condition" target="_blank" class="yellow">Condizioni di utilizzo <br>
      Contatti - Credits</a></div>

      
    <div class="d-flex space">
      <div class="d-flex lomb-container">

        <div class="my5">
              Con il contributo di 
        </div>  

        <a href="https://www.regione.lombardia.it/wps/portal/istituzionale/" target="_blank">
          <div class="bg-white">
              <img src="../img/Home/lombardia-logo.png" alt="">
          </div>
        </a>
            
      </div>
    </div>

    <div class="dropdown" @click="toggleDropdown">
      <button class="dropbtn"><i class="fa-solid fa-bars"></i></button>
      <div class="dropdown-content">
        <a href="/" v-if="$router.currentRoute.name === 'document'">Home</a>
        <a href="/document" v-if="$router.currentRoute.name === 'home'">Entra</a>
        <a href="/condition" target="_blank">Condizioni di utilizzo <br>Contatti - Credits</a>
      </div>
    </div>
      
    


    <router-view/>
  </header>
</template>


<style scoped lang="scss">
  header{
    height: 70px;
    width: 100%;
    background-color: #333d29;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: large;
  }

  #logo-container{
    width: 100px;
    height: 100%;
    min-width: 100px;
  }img{
    width: 100%;
    height: 100%;
  }

  .document-name{
    color: white;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a{
    text-decoration: none;
    color: white;
    justify-content: center;
    align-items: center;
    &:hover{
      color: #c2c5aa;
    }
  }

  .mobile-container{
    display: none;
  }

  .lomb-container{
    justify-content: center;
    align-items: center;
    color: white;
  }

  .bg-white{
    background-color: white;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin-left: 10px;
  }

  .yellow{
    color: #c2c5aa;
    &:hover{
      color: white;
    }
  }

  .space{
    text-align: center;
    margin: 0px 10px;
  }

  .dropbtn {
  background-color: #b6ad90;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  &:hover{
    background-color: #c2c5aa;
  }
}

.dropdown {
  position: relative;
  display: none;
}

.block{
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  left: -115px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover{
    background-color: #f1f1f1
  }
}


  @media screen and (max-width: 1100px) {
      header{
        font-size: small;
      }

      .space{
        margin: 0px;
      }
  } 

  @media screen and (max-width: 900px) {
      .hide{
        display: none;
      }

      .dropdown{
        display: block;
      }
  }

  @media screen and (max-width: 720px) {
      .lomb-container{
        flex-direction: column;
        text-align: center;
      }

      .dropdown-content{
        bottom: -100px;
      }
  }

  @media screen and (max-width: 600px) {

    header{
      padding: 5px 2px;
    }
    
    .dropdown{
      margin: 0px 5px;
    }
    .dropbtn{
      padding: 12px;
      font-size: 10px;
    }

    .dropdown-content{
      bottom: -110px;
      left: -130px;
    }

    .my5{
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 400px) {
    .document-name{
      font-size: smaller;
    }

    #logo-container{
      min-width: 80px;
    }
      .bg-white{
        width: 70px;
      }
  }
</style>
