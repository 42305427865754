<script>
import documentsData from '@/documents.json';

export default {
  name: 'MainDocView',
  components: {},

  props: {
    selectedFilter: String,
  },

  data() {
    return {
      documents: documentsData,
      startIndex: 0,
      endIndex: 4,
      isFullScreen: false,
      fullScreenImage: null,
    };
  },

  computed: {
    filteredDocuments() {
      if (!this.selectedFilter) {
        return this.documents;
      } else {
        return this.documents.filter(document => document.filter === this.selectedFilter);
      }
    },
  },

  watch: {
    selectedFilter: 'resetStartIndex',
  },

  methods: {
    resetStartIndex() {
      this.startIndex = 0;
      this.endIndex = 4;
    },
    openFullScreen(document, imageUrl) {
      this.isFullScreen = true;
      this.fullScreenImage = imageUrl;
    },
    closeFullScreen() {
      this.isFullScreen = false;
      this.fullScreenImage = null;
    },
  },
};
</script>


<template>
  <div id="doc-container" ref="docContainer">

    <div class="doc" v-for="document in filteredDocuments.slice(startIndex, startIndex + 4)" :key="document.id">

      <div class="img-container">
        <img :src="document.images[0]" alt="" @click="openFullScreen(document, document.images[0])">
      </div>
      
      <div class="doc-info">

        <ul>
          <li class="title">
            <h4>{{ document.name }}</h4>
          </li>

          <router-link class="documentation-link" :to="'/docinfo/' + document.id">
            <li class="blue" v-if="document.research === 'y'">Documentazione</li>
          </router-link>

          <li>Anno: {{ document.year }}</li>
          <li>Descrizione:</li>
          <li class="description">{{ document.description }}</li>
        </ul>
      </div>
    </div>

    <div class="overlay" v-if="isFullScreen" @click="closeFullScreen()">
      <img :src="fullScreenImage" alt="">
    </div>

    <div class="buttons-container d-flex">
      <button @click="startIndex -= 4; endIndex -= 4" v-if="startIndex > 0"><i
          class="fa-solid fa-arrow-left"></i></button>
      <button @click="startIndex += 4" v-if="startIndex + 4 < filteredDocuments.length"><i
          class="fa-solid fa-arrow-right"></i></button>
    </div>

  </div>
</template>

<style lang="scss" scoped>
#doc-container {
  width: 80%;
  height: 100%;
  padding: 30px;
  background-color: #c2c5aa;
  overflow: scroll;
  overflow-x: hidden;
}

#doc-container::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.doc {
  width: 100%;
  height: 200px;
  display: flex;
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 20px;
  overflow: hidden;
}



.img-container {
  min-width: 450px;
  height: 100%;
  overflow: hidden;
  background-color: #b6ad90;
  display: flex;
  justify-content: center;
}

img {
  height: 100%;
  cursor: pointer;
}

img:hover {
  transform: scale(1.05);
  transition: transform 1.5s ease;
}

.doc-info {
  width: 100%;
  height: 100%;
  background-color: #b6ad90;
  padding: 10px 20px 0px 20px;
}


ul::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

ul {
  height: 100%;
  overflow: auto;

}

li {
  list-style: none;
  margin-bottom: 8px;
}

.title {
  text-align: center;
  font-size: larger;
}

h4 {
  background-color: #656d4a;
  padding: 2px 10px;
  border-radius: 10px;
}

.documentation-link {
  text-decoration: none;
  color: blue;
}

.buttons-container {
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
}

button {
  margin-right: 15px;
  height: 100%;
  width: 50px;
  border-radius: 50px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #333d29;
  border: none;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.overlay img {
  max-width: 100%;
  max-height: 80%;
  cursor: pointer;
}


@media screen and (max-width: 1100px) {
  .img-container {
    min-width: 320px;
  }
}

@media screen and (max-width: 845px) {
  ul {
    font-size: small;
  }
}

@media screen and (max-width: 750px) {
  .img-container {
    min-width: 240px;
  }
}

@media screen and (max-width: 580px) {

  .doc {
    height: 400px;
    flex-direction: column;
  }

  .img-container {
    min-width: 200px;
  }

  .doc-info {
    padding: 2px 20px 0px 20px;
  }

  ul {
    font-size: small;
  }
}

@media screen and (max-width: 560px) {

  .img-container {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 460px) {
  #doc-container {
    width: 70%;
    height: 100%;
    padding: 30px 10px;
  }
}</style>