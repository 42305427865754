<script>
export default {
  name: 'ImageViewer',

  props: {
    imageUrl: String,
    description: String,
  },
  methods: {
    closeViewer() {
      // Chiudi l'immagine a schermo intero
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="image-viewer" @click="closeViewer">

    <div class="nd-container">
      <div class="image-container">
        <img :src="imageUrl" alt="Immagine a schermo intero">
      </div>

      <div class="description-container">
        <div class="description">{{ description }}</div>
      </div>
    </div>
      


  </div>
</template>

<style scoped lang="scss">

.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ad;
  padding: 0px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nd-container{
  width: 100%;
  height: 90%;
}

.image-container {
  width: 100%;
  height: 80%;
  position: relative;
  bottom: 0px;
  display: flex;
  justify-content: center;
  border: solid 0.5px black;
}img{
  width: 100%;
  height: 100%;
}

.description-container{
  width: 100%;
  height: 20%;
  border: solid 0.5px black;
}.description {
  width: 100%;
  height: 100%;
  background-color: #333d29;
  color: white;
  overflow: overlay;
  padding: 30px 40px;
  line-height: 2;

}




@media screen and (max-width: 600px) {
      .image-viewer{
        padding: 0px 20px;
      }
  }

</style>