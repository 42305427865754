<script>
export default {
  name: 'SearchDocView',

  data() {
    return {
      selectedItem: null,
    };
  },

  methods: {
    handleClick(filter) {
    if (this.selectedItem === filter) {
      this.selectedItem = null;
    } else {
      this.selectedItem = filter;
    }
    
    this.$emit('reset-start-index'); 
    this.selectFilter(filter);
    },

    selectFilter(filter) {
      this.$emit('filter-selected', filter);
    },

    resetSelectedItem() {
      this.selectedItem = null;
    },
    
    resetFilterAndSelect() {
      this.resetSelectedItem();
      this.selectFilter('');
    },
  },
};
</script>

<template>
  <div id="search-container">
          
          <!-- <div id="search-bar">
            <input type="text" placeholder="Cerca">
          </div> -->
          
          <ul>
            <li class="open" :class="{ 'selected': selectedItem === 'Panorami e scene di guerra' }" @click="handleClick('Panorami e scene di guerra')"> Panorami e scene di guerra </li>

            <li class="open" :class="{ 'selected': selectedItem === 'Foto di gruppo' }" @click="handleClick('Foto di gruppo')"> Foto di gruppo </li>
            
            <li class="open" :class="{ 'selected': selectedItem === 'Foto individuali' }" @click="handleClick('Foto individuali')"> Foto individuali</li>

            <li class="open" :class="{ 'selected': selectedItem === 'Personalità illustri' }" @click="handleClick('Personalità illustri')"> Personalità illustri</li>

            <li class="open" :class="{ 'selected': selectedItem === 'Parate, raduni e cerimonie' }" @click="handleClick('Parate, raduni e cerimonie')"> Parate, raduni e cerimonie</li>

            <li class="open" :class="{ 'selected': selectedItem === 'Cimeli' }" @click="handleClick('Cimeli')"> Cimeli</li>

          </ul>

          <div id="reset-filter-container">
            <div id="reset-filter" @click="resetFilterAndSelect('')">
              Azzera Filtro
            </div>
          </div>

          <div class="social-container d-flex">
            <div class="social-icon"><a href="https://www.facebook.com/profile.php?id=61553590540541" target="_blank"><i class="fa-brands fa-facebook"></i></a></div>
            <div class="social-icon"><a href="https://www.instagram.com/archiviogranatieri/?igshid=NGVhN2U2NjQ0Yg%3D%3D" target="_blank"><i class="fa-brands fa-instagram"></i></a></div>
          </div>

  </div>
</template>

<style lang="scss" scoped>
    #search-container::-webkit-scrollbar {
    width: 0;
    background: transparent;
    }
    #search-container{
      width: 20%;
      background-color: #b6ad90;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 0px 20px;
      overflow: overlay;
    }ul{

    }.open{
      background-color: #656d4a;
      cursor: pointer;
      margin-bottom: 40px;
      width: 80%;
      border-radius: 0px 5px 5px 0px;
      list-style: none;
      padding: 5px 10px;
      transition: all 1s;
      &:hover{
        width: 90%;
        background-color: #a4ac86;
      }
    }

    .selected {
    background-color: #a4ac86;
    width: 90%;
    }

    #search-bar{
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;
    }input{
      background-color: lightgray;
      border-radius: 5px;
    }

    .social-container{
      justify-content: center;
      align-items: center;

    }

    .social-icon{
      font-size: 26px;
      margin-right: 10px;
      
    }a{

      text-decoration: none;
      color: #333d29;
      &:hover{
        color: white;
      }
    }i{
      cursor: pointer;
    }


    #reset-filter-container{
      display: flex;
      justify-content: center;
      margin: 20px 0px 20px 0px;
    }#reset-filter{
      background-color: #333d29;
      color: white;
      padding: 12px;
      border-radius: 42px;
      cursor: pointer;
      &:hover{
        padding: 12px 20px;
        transition: all 0.5s;
    }
    }

    @media screen and (max-width: 845px) {
      .open{
        font-size: small;
      }

      #reset-filter{
        font-size: small
      }
  }

  @media screen and (max-width: 650px) {
      #reset-filter{
        padding: 7px;
        &:hover{
          padding: 6px 10px;
          transition: all 0.5s;
        }
      }
  }

  @media screen and (max-width: 560px) {
      .open{
        width: 90%;
      }

      #reset-filter{
        padding: 4px;
        &:hover{
          padding: 4px;
          
        }
      }
    }
  
  @media screen and (max-width: 460px) {
    #search-container{
      width: 30%;
    }
  }

    
</style>