<script>
import documentsData from '@/documents.json';

export default {
  name: 'DocInfoView',
  data() {
    return {
      documents: documentsData,
      isImageOpen: false, 
      selectedImage: '', 
    };
  },
    computed: {
    document() {
      const documentId = this.$route.params.id;
      return this.documents.find(doc => doc.id === Number(documentId)); 
    },
  },

  methods: {
    openImage(image) {
      this.selectedImage = image;
      this.isImageOpen = true;
    },
    closeImage() {
      this.selectedImage = '';
      this.isImageOpen = false;
    },
  },

};
</script>

<template>
  <div class="doc-info-view">

    <div class="title-container">
      <h2>{{ document.name }}</h2>
    </div>

    <div class="img-container">
      <div class="img" v-for="(image, index) in document.images" :key="index">
          <img :src="image" alt="" @click="openImage(image)">
      </div>
    </div>

    <div class="image-modal" v-if="isImageOpen" @click="closeImage">
      <div class="modal-content" :style="{ backgroundImage: 'url(' + selectedImage + ')' }">
      </div>
    </div>
    
    <div class="info-container">

      <a href="/document" class="text-decoration-none">
        
        <div class="go-back">
          <i class="fa-solid fa-arrow-left fontnd"></i> 
        </div>

      </a>

      <div class="description-container">
        <p>Descrizione: <br> {{ document.info }}</p>
      </div>

    </div>
    
  </div>
</template>


<style scoped lang="scss">

.doc-info-view{
  width: 100%;
  height: 100%;
}
  .title-container{
    background-color: #333d29;
    color: white;
    text-align: center;
    padding: 30px 0px;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
    background-color: #c2c5aa;
  }.img{
    height: 300px;
    padding: 10px;
    overflow:hidden ;
    justify-content: center;
    display: flex;
    
  }img{
    height: 100%;
    overflow:hidden ;
    &:hover{
    transform: scale(1.05);
    transition: transform 1.5s ease; 
    cursor: pointer;
    }
  }

  .image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modal-content{
    width: 80%;
    height: 90%;
    background-repeat:no-repeat ;
    background-position: center;
    background-size: contain;

  }

  .go-back{
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: white;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }.fontnd{
    font-size: xx-large;
    color: black;
  }

  .info-container{
    background-color: #333d29;
    color: white;
    padding: 10px 20px;
  }

  .description-container{
    padding: 10px 150px;
    text-align: center;
    min-height: 1000px;
  }p{
    line-height: 2.5;
  }


  @media screen and (max-width: 1200px) {
    .description-container{
      padding: 10px 136px;
    }
  }


  
  @media screen and (max-width: 1000px) {
    .description-container{
      padding: 10px 90px;
    }
  }

  @media screen and (max-width: 850px) {
    .description-container{
      padding: 10px 30px;
    }
  }

  @media screen and (max-width: 500px) {
    .description-container{
      padding: 10px 0px;
    }

    .img-container{
      padding: 0px;
    }
  }

</style>
